var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCinemedicSupervisorDataContext } from "../Data/CinemedicSupervisorDataContext";
import React from "react";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
import useDynamicVirtualPatient from "../../DemoMode/DynamicVirtualPatientStore";
export function useStateSelectHook() {
    var _a = useCinemedicSupervisorDataContext(), setActiveInterpolationData = _a.setActiveInterpolationData, activeInterpolationData = _a.activeInterpolationData;
    var _b = useVirtualPatientStore(), virtualPatientState = _b.virtualPatientState, updateVirtualPatientState = _b.updateVirtualPatientState;
    var _c = useDynamicVirtualPatient(), interpolationAlpha = _c.interpolationAlpha, interpolationDurationSeconds = _c.interpolationDurationSeconds, setStates = _c.setStates, setIsPlaying = _c.setIsPlaying;
    var virtualPatientStateRef = React.useRef(virtualPatientState);
    React.useEffect(function () {
        virtualPatientStateRef.current = virtualPatientState;
    }, [virtualPatientState]);
    var setTargetState = React.useCallback(function (targetState, targetStateName, durationInSeconds) {
        setActiveInterpolationData({
            state0: virtualPatientStateRef.current.medicalState,
            state1: targetState,
            durationInSeconds: durationInSeconds,
            targetStateName: targetStateName,
        });
    }, [setActiveInterpolationData]);
    var modifyImmediateVitalsAndContinue = React.useCallback(function (newCurrentVitals) {
        if (!activeInterpolationData) {
            return;
        }
        var newState0 = __assign(__assign({}, virtualPatientStateRef.current.medicalState), { vitals: newCurrentVitals });
        var remainingTime = interpolationDurationSeconds - (interpolationAlpha * interpolationDurationSeconds);
        updateVirtualPatientState(function (state) {
            return __assign(__assign({}, state), { medicalState: newState0 });
        });
        setActiveInterpolationData({
            state0: newState0,
            state1: activeInterpolationData.state1,
            durationInSeconds: remainingTime,
            targetStateName: activeInterpolationData.targetStateName,
        });
        setStates(newState0, activeInterpolationData.state1);
        setIsPlaying(true);
    }, [setActiveInterpolationData, interpolationDurationSeconds, interpolationAlpha, activeInterpolationData, setStates, setIsPlaying, updateVirtualPatientState]);
    return {
        setTargetState: setTargetState,
        modifyImmediateVitalsAndContinue: modifyImmediateVitalsAndContinue
    };
}
