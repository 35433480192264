import { DefaultStaticData } from "./PatientState";
import { LungSound } from "./components/Breathing";
import { HeartSound } from "./components/Circulation";
import { DefaultDeviceStatus } from "./components/Devices";
import { DefaultDisabilityState } from "./components/Disability";
import { DefaultExposureState, DefaultSkinState } from "./components/Skin";
import { DefaultVitalsState } from "./components/Vitals";
export var DefaultMedicalState = {
    vitals: DefaultVitalsState,
    breathingSounds: {
        normal: 0.05,
        grunting: 0,
        gurgling: 0,
        stridor: 0,
        wheezing: 0,
        snoring: 0,
        leftLungSound: LungSound.Normal,
        rightLungSound: LungSound.Normal
    },
    workOfBreathing: {
        headBobbing: 0,
        nasalFlaring: 0,
        retractions: 0,
        sterno: 0
    },
    circulation: {
        capillaryRefillSeconds: 3,
        heartSound: HeartSound.Normal
    },
    skin: DefaultSkinState,
    disability: DefaultDisabilityState,
    exposure: DefaultExposureState
};
export var DefaultExaminationState = {
    clothed: true,
    eyesOpen: false,
    mouthOpen: false
};
export var DefaultVirtualPatientState = {
    deviceStatus: DefaultDeviceStatus,
    examinationState: DefaultExaminationState,
    medicalState: DefaultMedicalState,
    name: undefined,
    staticData: DefaultStaticData,
    progressionData: undefined
};
