import React, { useEffect } from "react";
import useDynamicVirtualPatient from "../../DemoMode/DynamicVirtualPatientStore";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
export function useInterpolationManagementHook(setCurrentStateName) {
    var _a = React.useState(null), activeInterpolationData = _a[0], setActiveInterpolationData = _a[1];
    var _b = useDynamicVirtualPatient(), isPlaying = _b.isPlaying, interpolationDurationSeconds = _b.interpolationDurationSeconds;
    var interpolationDurationSecondsRef = React.useRef(interpolationDurationSeconds);
    var _c = useDynamicVirtualPatient.getState(), setInterpolationTimeSeconds = _c.setInterpolationTimeSeconds, setInterpolationDurationSeconds = _c.setInterpolationDurationSeconds, setStates = _c.setStates, setIsPlaying = _c.setIsPlaying;
    var updateVirtualPatientState = useVirtualPatientStore().updateVirtualPatientState;
    var interpolationControlRef = React.useRef(null);
    useEffect(function () {
        interpolationDurationSecondsRef.current = interpolationDurationSeconds;
    }, [interpolationDurationSeconds]);
    var interpolator = React.useCallback(function (onFinished) {
        var elapsedTime = 0;
        var intervalSeconds = 1;
        var timeoutId = null;
        var interpolate = function () {
            if (elapsedTime >= interpolationDurationSecondsRef.current) {
                timeoutId = null;
                onFinished();
                return;
            }
            elapsedTime += intervalSeconds;
            setInterpolationTimeSeconds(elapsedTime);
            timeoutId = setTimeout(interpolate, intervalSeconds * 1000);
        };
        return {
            pause: function () {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                    timeoutId = null;
                }
            },
            resume: function () {
                if (!timeoutId) {
                    interpolate();
                }
            },
        };
    }, [setInterpolationTimeSeconds]);
    var onFinished = React.useCallback(function () {
        setCurrentStateName(activeInterpolationData.targetStateName);
        setActiveInterpolationData(null);
        setIsPlaying(false);
    }, [setCurrentStateName, setActiveInterpolationData, setIsPlaying, activeInterpolationData]);
    React.useEffect(function () {
        if (!activeInterpolationData) {
            return;
        }
        // If the duration is zero, then just set the state and return
        if (activeInterpolationData.durationInSeconds <= 0) {
            setCurrentStateName(activeInterpolationData.targetStateName);
            setActiveInterpolationData(null);
            updateVirtualPatientState(function (state) {
                state.medicalState = structuredClone(activeInterpolationData.state1);
            });
            return;
        }
        setInterpolationDurationSeconds(activeInterpolationData.durationInSeconds);
        setStates(activeInterpolationData.state0, activeInterpolationData.state1);
        setIsPlaying(true);
        interpolationControlRef.current = interpolator(onFinished);
        return function () {
            if (interpolationControlRef.current) {
                interpolationControlRef.current.pause();
            }
        };
    }, [activeInterpolationData, interpolator, setInterpolationDurationSeconds, setStates, onFinished, setCurrentStateName, updateVirtualPatientState, setIsPlaying]);
    React.useEffect(function () {
        if (!interpolationControlRef.current) {
            return;
        }
        if (!isPlaying) {
            interpolationControlRef.current.pause();
        }
        else {
            interpolationControlRef.current.resume();
        }
    }, [isPlaying]);
    return {
        activeInterpolationData: activeInterpolationData,
        setActiveInterpolationData: setActiveInterpolationData,
    };
}
