var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
var VitalSignValue = function (_a) {
    var label = _a.label, currentValue = _a.currentValue, targetValue = _a.targetValue, unit = _a.unit, _b = _a.decimalPlaces, decimalPlaces = _b === void 0 ? 0 : _b;
    var showTarget = targetValue !== undefined && targetValue !== currentValue;
    var formatter = function (value, decimalPlaces) {
        if (decimalPlaces === void 0) { decimalPlaces = 0; }
        if (typeof value === "number") {
            return value.toFixed(decimalPlaces);
        }
        else {
            return value;
        }
    };
    return (_jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("p", __assign({ className: "text-sm text-gray-500" }, { children: label })), _jsxs("div", __assign({ className: "flex items-end space-x-1" }, { children: [_jsxs("div", __assign({ className: "text-xl font-medium text-gray-700 flex" }, { children: [_jsx("span", { children: formatter(currentValue, decimalPlaces) }), showTarget && (_jsxs("div", __assign({ className: "text-blue-500 w-24" }, { children: [" \u2192 ", formatter(targetValue)] })))] })), _jsxs("span", __assign({ className: "text-sm text-gray-500 w-12 leading-relaxed" }, { children: [" ", unit] }))] }))] })));
};
export default VitalSignValue;
