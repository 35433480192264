import { z } from "zod";
import { BloodPressureSchema } from "./BloodPressure";
export var VitalsSchema = z.object({
    heartRate: z.number().describe("beats / minute"),
    respiratoryRate: z.number().describe("breaths / minute"),
    spo2: z.number().describe("%"),
    etco2: z.number().describe("mmHg"),
    nibp: BloodPressureSchema,
    temperature: z.number().describe("°C"),
});
export var DefaultVitalsState = {
    heartRate: 60,
    respiratoryRate: 35,
    spo2: 100,
    nibp: { systolic: 120, diastolic: 60 },
    etco2: 35,
    temperature: 37,
};
