var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import VitalSignValue from "./VitalSignValue";
import { useVirtualPatientStore } from "../../../storage/VirtualPatientStore";
import { useCinemedicSupervisorDataContext } from "../Data/CinemedicSupervisorDataContext";
var VitalsSection = function (_a) {
    var onShowCustomStateModal = _a.onShowCustomStateModal;
    var virtualPatientState = useVirtualPatientStore().virtualPatientState;
    var activeInterpolationData = useCinemedicSupervisorDataContext().activeInterpolationData;
    var vitals = virtualPatientState.medicalState.vitals;
    var targetVitals = activeInterpolationData ? activeInterpolationData.state1.vitals : undefined;
    return (_jsxs("section", __assign({ className: "bg-white shadow rounded-lg p-4" }, { children: [_jsxs("div", __assign({ className: "flex items-center justify-between" }, { children: [_jsx("h2", __assign({ className: "text-base font-semibold text-gray-800" }, { children: "Vital Signs" })), _jsx("button", __assign({ className: "text-sm text-blue-500", onClick: onShowCustomStateModal }, { children: "Modify" }))] })), _jsxs("div", __assign({ className: "mt-3 space-y-3" }, { children: [_jsx(VitalSignValue, { label: "Pulse", currentValue: vitals.heartRate, targetValue: targetVitals === null || targetVitals === void 0 ? void 0 : targetVitals.heartRate, unit: "bpm" }), _jsx(VitalSignValue, { label: "Respiratory Rate", currentValue: vitals.respiratoryRate, targetValue: targetVitals === null || targetVitals === void 0 ? void 0 : targetVitals.respiratoryRate, unit: "bpm" }), _jsx(VitalSignValue, { label: "Blood Pressure", currentValue: "".concat(Math.round(vitals.nibp.systolic), "/").concat(Math.round(vitals.nibp.diastolic)), targetValue: targetVitals
                            ? "".concat(Math.round(targetVitals.nibp.systolic), "/").concat(Math.round(targetVitals.nibp.diastolic))
                            : undefined, unit: "mmHg" }), _jsx(VitalSignValue, { label: "SpO2", currentValue: vitals.spo2, targetValue: targetVitals === null || targetVitals === void 0 ? void 0 : targetVitals.spo2, unit: "%" }), _jsx(VitalSignValue, { label: "EtCO2", currentValue: vitals.etco2, targetValue: targetVitals === null || targetVitals === void 0 ? void 0 : targetVitals.etco2, unit: "mmHg" }), _jsx(VitalSignValue, { label: "Temperature", currentValue: vitals.temperature, targetValue: targetVitals === null || targetVitals === void 0 ? void 0 : targetVitals.temperature, unit: "\u00B0C", decimalPlaces: 1 })] }))] })));
};
export default VitalsSection;
