var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useDrag } from "@use-gesture/react";
import React from "react";
export var DraggableNumericInput = function (_a) {
    var label = _a.label, value = _a.value, onChange = _a.onChange, unit = _a.unit, originalValue = _a.originalValue, min = _a.min, max = _a.max, _b = _a.decimalPlaces, decimalPlaces = _b === void 0 ? 0 : _b;
    var _c = React.useState(false), dragging = _c[0], setDragging = _c[1];
    var bind = useDrag(function (_a) {
        var _b = _a.delta, dy = _b[1], last = _a.last;
        setDragging(!last);
        var increment = dy / 10; // Scale sensitivity
        var newValue = value - increment;
        if (min !== undefined && newValue < min) {
            newValue = min;
        }
        if (max !== undefined && newValue > max) {
            newValue = max;
        }
        if (last) {
            setDragging(false);
            newValue = Math.round(newValue);
        }
        onChange(newValue);
    });
    var showOriginal = originalValue !== undefined && originalValue.toFixed(decimalPlaces) !== value.toFixed(decimalPlaces);
    return (_jsxs("div", __assign({ className: "flex justify-between" }, { children: [label && _jsx("p", __assign({ className: "text-sm text-gray-500" }, { children: label })), _jsxs("div", __assign({}, bind(), { className: "text-xl font-medium cursor-pointer select-none touch-none text-gray-700 transition px-2 rounded-full border bg-white flex items-center justify-end space-x-1\n                    ".concat(dragging ? 'bg-blue-100' : ''), style: {
                    userSelect: 'none',
                    touchAction: 'none',
                } }, { children: [showOriginal && (_jsxs(_Fragment, { children: [originalValue.toFixed(decimalPlaces), " \u2192"] })), _jsx("span", __assign({ className: showOriginal ? "text-blue-500" : "" }, { children: value.toFixed(decimalPlaces) })), unit && _jsxs("span", __assign({ className: "text-sm text-gray-500" }, { children: [" ", unit] }))] }))] })));
};
var EditableVitalSignBase = function (_a) {
    var label = _a.label, unit = _a.unit, children = _a.children;
    return (_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx("p", __assign({ className: "text-sm text-gray-500" }, { children: label })), _jsxs("div", __assign({ className: "flex items-center space-x-2" }, { children: [children, unit && _jsx("span", __assign({ className: "text-sm text-gray-500" }, { children: unit }))] }))] })));
};
export var EditableVitalSign = function (_a) {
    var label = _a.label, value = _a.value, onChange = _a.onChange, unit = _a.unit, originalValue = _a.originalValue, min = _a.min, max = _a.max, _b = _a.decimalPlaces, decimalPlaces = _b === void 0 ? 0 : _b;
    return (_jsxs("div", __assign({ className: "flex justify-between items-center" }, { children: [_jsx("p", __assign({ className: "text-sm text-gray-500" }, { children: label })), _jsx(DraggableNumericInput, { value: value, onChange: onChange, unit: unit, originalValue: originalValue, min: min, max: max, decimalPlaces: decimalPlaces })] })));
};
export var EditableBloodPressure = function (_a) {
    var label = _a.label, systolic = _a.systolic, diastolic = _a.diastolic, unit = _a.unit, onChange = _a.onChange, originalSystolic = _a.originalSystolic, originalDiastolic = _a.originalDiastolic, minSystolic = _a.minSystolic, maxSystolic = _a.maxSystolic, minDiastolic = _a.minDiastolic, maxDiastolic = _a.maxDiastolic;
    return (_jsxs(EditableVitalSignBase, __assign({ label: label, unit: unit }, { children: [_jsx(DraggableNumericInput, { value: systolic, onChange: function (newSystolic) { onChange(newSystolic, diastolic); }, originalValue: originalSystolic, min: minSystolic, max: maxSystolic }), _jsx("span", __assign({ className: "text-gray-500" }, { children: "/" })), _jsx(DraggableNumericInput, { value: diastolic, onChange: function (newDiastolic) { onChange(systolic, newDiastolic); }, originalValue: originalDiastolic, min: minDiastolic, max: maxDiastolic })] })));
};
